import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Operation,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { GeishaProvider } from '@resily/geisha'
import { useProfiler, Breadcrumb } from '@sentry/react'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import { SentryLink, GraphQLBreadcrumb } from 'apollo-link-sentry'
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import localforage from 'localforage'
import React, { VFC, useState, useEffect } from 'react'

import {
  configureEndpoint,
  isDevelopment,
  configureLaunchDarkly,
  isProduction,
  isInternalEnvironment,
} from './config'
import { AlertModalContextProvider } from './contexts/AlertModalContext'
import { ApolloContextProvider } from './contexts/ApolloContext'
import { AuthProvider } from './contexts/AuthContext'
import { EditingStateContextProvider } from './contexts/EditingStateContext'
import { KrFilterContextProvider } from './contexts/KrFilterContext'
import { OkrTermIdContextProvider } from './contexts/OkrTermIdContext'
import { OrganizationContextProvider } from './contexts/OrganizationContext'
import { UserContextProvider } from './contexts/UserContext'
import { ErrorBoundary } from './error'
import { useTranslation } from './i18n'
import result from './introspectionResult'
import { ErrorWithShouldIgnore, RequestContextLink } from './lib/apollo'
import { FetchContextProvider } from './lib/client'
import { destruct } from './lib/domain/auth'
import { DEFAULT_FEATURE_TOGGLE_FLAGS } from './lib/featureToggle'
import { createTrackerApolloLink } from './lib/openReplay/plugins'
import { startPollingUpdateLastAccessed } from './pollUpdateLastAccessed'
import { AppRoutes } from './routes'
import * as urls from './urls'

if (isDevelopment() || isInternalEnvironment()) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    collapseGroups: true,
    logOnDifferentValues: true,
  })
}

const config = configureEndpoint({
  protocol: window.location.protocol,
  host: window.location.host,
})

// Apollo Clientのインメモリキャッシュを永続化するためにlocalForageを利用
localforage.config({
  name: 'resily',
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
})

const apolloRequestContextLink = new RequestContextLink()
const trackerApolloLink = createTrackerApolloLink()

const ReactApp: VFC = () => {
  useProfiler('ReactApp')
  const { i18n } = useTranslation()

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
  useEffect(() => {
    const initClient = async () => {
      const cache = new InMemoryCache({
        possibleTypes: result.possibleTypes,
        typePolicies: {
          Activity: {
            keyFields: ['id', 'activityType'],
          },
          Query: {
            fields: {
              okrNode: relayStylePagination(['okrNodeId', 'objectiveId', 'keyResultId']),
              findActivitiesByUserId: relayStylePagination(['resourceType', 'userId', 'okrTermId']),
              findActivitiesByKeyResultIds: relayStylePagination(['keyResultIds']),
              findCheckinSummariesByOkrNodeId: relayStylePagination(['okrTermId']),
              keyResultProgressRateHistories: relayStylePagination([
                'groupIds',
                'userIds',
                'okrNodeIds',
                'termId',
              ]),
            },
          },
        },
      })
      await persistCache({
        cache,
        storage: new LocalForageWrapper(localforage),
        debounce: 2000,
        maxSize: 10485760, // キャッシュストレージの最大バイト数 10MB
      })
      setClient(
        new ApolloClient({
          link: ApolloLink.from([
            apolloRequestContextLink,
            ...(trackerApolloLink ? [trackerApolloLink] : []),
            onError(({ graphQLErrors, networkError, operation }) => {
              if (graphQLErrors) {
                if (isDevelopment()) {
                  graphQLErrors.forEach(({ message, locations, path }) =>
                    // eslint-disable-next-line no-console
                    console.error(
                      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                  )
                }
              }
              // 未認証 or セッション切れのハンドリング
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const checkError = (error: any): error is { statusCode: number } =>
                error != null && error.statusCode
              if (checkError(networkError) && networkError.statusCode === 401) {
                if (operation.getContext().ignoreForceRedirect as boolean) {
                  const error = networkError as unknown as ErrorWithShouldIgnore
                  error.shouldIgnore = true
                } else {
                  destruct()
                  window.location.href = `${urls.signIn}?from=${encodeURIComponent(
                    window.location.href,
                  )}`
                }
              }
            }),
            new SentryLink({
              uri: `${config.base}/query`,
              setTransaction: false,
              attachBreadcrumbs: {
                includeQuery: true,
                includeError: true,
                transform: (breadcrumb: GraphQLBreadcrumb, operation: Operation): Breadcrumb => {
                  const context = operation.getContext()

                  breadcrumb.data.context = {
                    requestId: context.response?.headers?.get('x-request-id'),
                  }
                  return breadcrumb
                },
              },
            }),
            new HttpLink({
              uri: `${config.base}/query`,
              credentials: 'include',
              fetch: (input: RequestInfo, init?: RequestInit): Promise<Response> =>
                window.fetch(input, init),
            }),
          ]),
          cache,
          connectToDevTools: !isProduction(),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'cache-and-network',
              nextFetchPolicy: 'cache-first',
            },
          },
        }),
      )
    }
    initClient().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      localforage.clear().catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
    })
    startPollingUpdateLastAccessed()
  }, [])

  if (!client) {
    return null
  }

  return (
    <ErrorBoundary>
      <GeishaProvider datesLocale={i18n.language === 'ja' ? 'ja' : 'en'}>
        <ApolloProvider client={client}>
          <ApolloContextProvider middleware={apolloRequestContextLink}>
            <FetchContextProvider>
              <UserContextProvider>
                <OrganizationContextProvider>
                  <AuthProvider>
                    <OkrTermIdContextProvider>
                      <AlertModalContextProvider>
                        <EditingStateContextProvider>
                          <KrFilterContextProvider>
                            <AppRoutes />
                          </KrFilterContextProvider>
                        </EditingStateContextProvider>
                      </AlertModalContextProvider>
                    </OkrTermIdContextProvider>
                  </AuthProvider>
                </OrganizationContextProvider>
              </UserContextProvider>
            </FetchContextProvider>
          </ApolloContextProvider>
        </ApolloProvider>
      </GeishaProvider>
    </ErrorBoundary>
  )
}

ReactApp.displayName = 'ReactApp'

// Launch Darklyの初期化を遅らせるため
// 実態はuseLDClient()をcallしている箇所で初期化処理を行っています
export const App = withLDProvider({
  clientSideID: configureLaunchDarkly().clientSideID,
  user: {
    key: undefined,
    anonymous: true,
  },
  options: { bootstrap: 'localStorage' },
  deferInitialization: true,
  flags: DEFAULT_FEATURE_TOGGLE_FLAGS,
})(ReactApp)
